<template>
  <layout-content-horizontal-nav :nav-menu-items="navMenuItems">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->
    <template #navbar>
      <div
        class="navbar-content-container"
        :class="{ 'expanded-search': shallShowFullSearch }"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-app-bar-nav-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            @click="drawerMenu = true"
          ></v-app-bar-nav-icon>
          <Logo />
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center" v-if="!$vuetify.breakpoint.mdAndDown">
          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            class="me-4"
          ></app-bar-search> -->

          <horizontal-nav-menu
            :nav-menu-items="navMenuItems"
            :class="{
              'horizontal-nav-menu-boxed mx-auto': appContentWidth === 'boxed',
            }"
          ></horizontal-nav-menu>

          <!-- <app-bar-theme-switcher class="mx-4"></app-bar-theme-switcher>
          <app-bar-i18n></app-bar-i18n> -->
          <!-- <app-bar-notification></app-bar-notification> -->
          <app-bar-user-menu class="ms-2"></app-bar-user-menu>
        </div>
      </div>
      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </template>

    <!-- Slot: footer -->
    <!-- <template #footer>
      <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <a
          href="https://themeselection.com"
          class="text-decoration-none"
        >ThemeSelection</a>, All rights Reserved</span>
        <div class="d-flex align-center">
          <span>Hand-crafted &amp; Made with</span>
          <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon>
        </div>
      </div>
    </template> -->

    <!-- App Content -->
    <!-- <template #v-app-content>
      <app-customizer class="d-none d-md-block"></app-customizer>
    </template> -->

    <!-- Add a navigation bar -->
    <v-navigation-drawer v-model="drawerMenu" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item
            v-for="(item, index) in navMenuItems"
            :key="index"
            :to="{ name: item.to }"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>

          <v-list-item :to="''" @click.stop="logoutUser">
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Navigation bar ends -->
  </layout-content-horizontal-nav>
</template>

<script>
import LayoutContentHorizontalNav from "@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue";
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'

// App Bar Components
// import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarI18n from "@core/layouts/components/app-bar/AppBarI18n.vue";
import AppBarThemeSwitcher from "@core/layouts/components/app-bar/AppBarThemeSwitcher.vue";
import AppBarUserMenu from "@core/layouts/components/app-bar/AppBarUserMenu.vue";
// import AppBarNotification from '@core/layouts/components/app-bar/AppBarNotification.vue'
import Logo from "@/components/general/Logo";

// Memu nav item modified
import HorizontalNavMenu from "@/@core/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue";
import useAppConfig from "@core/@app-config/useAppConfig";

// Search Data
import appBarSearchData from "@/assets/app-bar-search-data";

import { ref, watch } from "@vue/composition-api";

import themeConfig from "@themeConfig";
import { mdiHeartOutline } from "@mdi/js";
import { useUsers } from "@/composables/users";

export default {
  components: {
    LayoutContentHorizontalNav,
    // AppCustomizer,

    // App Bar Components
    // AppBarSearch,
    AppBarI18n,
    AppBarThemeSwitcher,
    AppBarUserMenu,
    // AppBarNotification,
    Logo,
    HorizontalNavMenu,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { appContentWidth } = useAppConfig();
    const { logoutUser } = useUsers();

    // Search
    const drawerMenu = ref(false);
    const appBarSearchQuery = ref("");
    const shallShowFullSearch = ref(false);
    const maxItemsInGroup = 5;
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    });
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      };
    });

    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true;

      const itemGroup = (() => {
        if (item.to !== undefined) return "pages";
        if (item.size !== undefined) return "files";
        if (item.email !== undefined) return "contacts";

        return null;
      })();

      const isMatched =
        itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
        -1;

      if (isMatched) {
        if (itemGroup === "pages") totalItemsInGroup.value.pages += 1;
        else if (itemGroup === "files") totalItemsInGroup.value.files += 1;
        else if (itemGroup === "contacts")
          totalItemsInGroup.value.contacts += 1;
      }

      return (
        appBarSearchQuery.value &&
        isMatched &&
        totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
      );
    };

    return {
      drawerMenu,
      appContentWidth,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      // App Config
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Icons
      icons: {
        mdiHeartOutline,
      },

      logoutUser,
    };
  },
};
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
