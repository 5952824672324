import { getCurrentInstance, onMounted } from "@vue/composition-api";
import { useRouter } from "@core/utils";
import { initialAbility } from "@/plugins/acl/config";

export function useUsers() {
  const vm = getCurrentInstance().proxy;
  const { router } = useRouter();

  const logoutUser = () => {
    // Remove userData from localStorage
    // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    localStorage.removeItem("accessToken");

    // Remove userData & Ability from localStorage
    localStorage.removeItem("userData");
    localStorage.removeItem("userAbility");

    // Reset ability
    vm.$ability.update(initialAbility);

    // Redirect to login page
    router.push({ name: "auth-login" });
  };

  onMounted(() => {});

  return {
    logoutUser,
  };
}
