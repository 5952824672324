<template>
  <span>{{ roleName }}</span>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  props: {
    role: {
      default: () => null,
      type: Object
    }
  },
  setup(props) {
    const { role } = props;
    const roleName = computed(() => role?.name === 'worker' && 'Proveedor' || role?.name);

    return {

      roleName
    };
  },
}
</script>